import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { PACKAGES } from '@components/shared/PrincingCardsV2/common';
import { useRouter } from 'next/router';
import useGetStartedCtaLink from '@hooks/useGetStartedCtaLink/useGetStartedCtaLink';
import { getResizedImageUrl } from '@lib/image';
import styles from './DesignPackages.module.scss';
import PackageCards from './PackageCards/PackageCards';

interface IDesignPackages {
  className?: string;
  merch?: boolean;
  cards?: ReactElement;
  cardsClassName?: string;
}

const DesignPackages = ({
  className = '',
  cardsClassName = '',
  merch,
  cards,
}: IDesignPackages) => {
  const router = useRouter();
  const link = useGetStartedCtaLink();

  const handleCTA = (pkg: PACKAGES) => {
    if (pkg === PACKAGES.AT_HOME) {
      return router.push('/in-person');
    }
    if (merch) {
      let giftQuery = 'ONLINE';
      if (pkg === PACKAGES.MERCH) {
        giftQuery = 'MERCH_ONLY';
      }
      return router.push(`/gift/checkout?gift=${giftQuery}`);
    }
    return router.push(link);
  };

  const packageImageMobile = getResizedImageUrl({
    url: 'https://static.havenly.com/home/design_packages_m2.jpg',
    quality: 80,
  });

  return (
    <section
      id="packages-section"
      className={styles.Root}
    >
      <picture>
        <source
          srcSet={getResizedImageUrl({
            url: 'https://static.havenly.com/home/design_packages.jpg',
          })}
          media={'(min-width: 1440px)'}
        />
        <source
          srcSet={getResizedImageUrl({
            url: 'https://static.havenly.com/home/design_packages_tablet.jpg',
          })}
          media={'(min-width: 1024px)'}
        />
        <source
          srcSet={getResizedImageUrl({
            url: 'https://static.havenly.com/home/design_packages_tablet2.jpg',
          })}
          media={'(min-width: 768px)'}
        />
        <img src={packageImageMobile} className={styles.BgImage} alt="Havenly Packages" />
      </picture>
      <div
        className={classNames(
          styles.Container,
          className,
          styles.isNewHomePage
        )}
      >
        <PackageCards CTA={handleCTA} cards={cards} className={cardsClassName} />
      </div>
    </section>
  );
};

export default DesignPackages;
