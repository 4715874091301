import React, { ReactElement, useEffect } from 'react';
import analyticsTrack from '@lib/analytics/analyticsTrack';
import useHolidaySale from '@hooks/useHolidaySale/useHolidaySale';
import classNames from 'classnames';
import { useTheme } from '@components/theme';
import PackageCard from '@components/shared/PrincingCardsV2/PackageCard/PackageCard';
import { PACKAGE_DETAILS, PACKAGE_NAMES } from '@components/shared/PrincingCardsV2/common';

import styles from './PackageCards.module.scss';

interface IPackageCardsProps {
  CTA: (props: any) => void;
  className?: string;
  cards?: ReactElement;
}

export default function PackageCards({
  CTA,
  className = '',
  cards
}: IPackageCardsProps) {
  const holidaySale = useHolidaySale();
  const theme = useTheme();

  useEffect(() => {
    analyticsTrack('package_cards_viewed');
  }, []);

  return (
    <div className={styles.Root}>
      <div className={styles.Header}>
        <p className={classNames(
          styles.Eyebrow,
          theme.styles.Overline
        )}
        >
          Our Design Packages
        </p>
        <div className={styles.sectionDesc}>
          Our interior design services are tailored to your space, style, and budget.
        </div>
      </div>
      <div className={classNames(styles.CardsWrapper, className)} id="package-types">
        {cards ? (
          <>{cards}</>
        ) : (
          <>
            <PackageCard
              CTA={CTA}
              type={PACKAGE_NAMES.AT_HOME}
              title={PACKAGE_DETAILS[PACKAGE_NAMES.AT_HOME].title}
              useCase={PACKAGE_DETAILS[PACKAGE_NAMES.AT_HOME].description}
              showLocations
              locations={PACKAGE_DETAILS[PACKAGE_NAMES.AT_HOME].locations}
              holidaySale={holidaySale}
            />

            <PackageCard
              CTA={CTA}
              type={PACKAGE_NAMES.FULL}
              title={PACKAGE_DETAILS[PACKAGE_NAMES.FULL].title}
              useCase={PACKAGE_DETAILS[PACKAGE_NAMES.FULL].description}
              holidaySale={holidaySale}
            />
          </>
        )}
      </div>
    </div>
  );
}
